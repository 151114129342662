import emailjs from 'emailjs-com';

export default async function sendEmail(event) {
  let sent = false;

  event.preventDefault();

  const { service, template, user } = {
    service: process.env.GATSBY_EMAIL_SERVICE_ID,
    template: process.env.GATSBY_EMAIL_TEMPLATE,
    user: process.env.GATSBY_EMAIL_USER,
  };

  const res = await emailjs.sendForm(service, template, event.target, user);

  try {
    console.log(res.text);
    sent = true;
    return sent;
  } catch (error) {
    console.log(error);
    sent = false;
    return 'error';
  }
}
