import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import { BsFillTelephoneFill } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import SEO from '../components/layout/SEO';
import Breadcrumb from '../components/layout/Breadcrumb';
import ContactForm from '../components/form/ContactForm';

const ContactSctyles = styled.div`
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
  }

  @media only screen and (max-width: 700px) {
    .grid {
      grid-template-columns: 1fr;
    }

    .information {
      padding-bottom: 60px;
    }
  }

  .hr {
    width: 10%;
    background-color: var(--accent);
    height: 2px;
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
  }

  .contact-information {
    background: whitesmoke;
    padding: 20px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding-bottom: 20px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .contact-icon {
    background: var(--accent);
    padding: 7px;
    margin-right: 15px;

    svg {
      fill: whitesmoke;
      padding: 0;
      margin: 0;
    }
  }
`;

export default function ContactPage({ location, data }) {
  const { settings } = data;

  return (
    <ContactSctyles>
      <SEO title="Contact Us" location={location.href} />

      <Breadcrumb>
        <li>
          <Link to="/">Home</Link>
        </li>

        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </Breadcrumb>

      <div className="container grid">
        <div className="contact">
          <h2>Contact</h2>
          <div className="hr" />
          <p>
            If you are interested in finding out how The UKSI can help you
            manage your buildings. For more information on our services please
            contact us.
          </p>
          <ContactForm />
        </div>

        <div className="information">
          <h2>Information</h2>
          <div className="hr" />
          <div className="contact-information">
            <ul>
              <li>
                <span className="contact-icon">
                  <AiOutlineMail />
                </span>
                <a href={`mailto:${settings.email}`}>{settings.email}</a>
              </li>

              <li>
                <span className="contact-icon">
                  <BsFillTelephoneFill />
                </span>
                <a href={`tel:${settings.phone}`}>{settings.phone}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ContactSctyles>
  );
}

export const query = graphql`
  {
    settings: sanitySiteSettings {
      phone
      email
    }
  }
`;

ContactPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
};
