import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import useForm from '../../utils/useForm';
import CallToAction from '../CallToAction';

import '../../styles/CustomBootStrapStyles.css';
import sendEmail from '../../utils/sendEmail';

const ContactFormStyles = styled.div`
  padding: 30px 0;
  label,
  input,
  textarea {
    font-family: 'Montserrat', sans-serif;
  }
`;

export default function ContacForm() {
  const { inputs, handleChange } = useForm();
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleFormSubmit(event) {
    event.preventDefault();

    const send = await sendEmail(event);

    if (inputs.maple) {
      alert('There has been an error, Please try again');
    }

    if (send === true) {
      setSent(true);
      setLoading(true);
    }
  }

  if (sent) {
    return (
      <Alert className="alert alert-success">
        <p>Your message has been sent</p>
      </Alert>
    );
  }

  return (
    <ContactFormStyles>
      <Form onSubmit={handleFormSubmit}>
        <Form.Control
          type="hidden"
          id="contact_email"
          name="contact__email"
          placeholder="contact_email"
          onChange={handleChange}
          value="tom_canham@yahoo.co.uk"
          required
          disabled
        />

        <Form.Control
          type="hidden"
          id="maple"
          name="maple"
          placeholder="maple"
          onChange={handleChange}
          value={inputs.maple || ''}
          disabled
        />

        <Form.Label htmlFor="name">Your Name:</Form.Label>
        <Form.Control
          type="text"
          id="name"
          name="name"
          placeholder="Name*"
          onChange={handleChange}
          value={inputs.name || ''}
          required
        />
        <br />

        <Form.Label htmlFor="phone">Your Phone:</Form.Label>
        <Form.Control
          type="tel"
          id="phone"
          name="phone"
          placeholder="Phone*"
          onChange={handleChange}
          value={inputs.phone || ''}
          required
        />
        <br />

        <Form.Label htmlFor="email">Your Email:</Form.Label>
        <Form.Control
          type="email"
          id="email"
          name="email"
          placeholder="email"
          onChange={handleChange}
          value={inputs.email || ''}
          required
        />
        <br />

        <Form.Label htmlFor="subject">Subject:</Form.Label>
        <Form.Control
          type="subject"
          id="subject"
          name="subject"
          placeholder="subject"
          onChange={handleChange}
          value={inputs.subject || ''}
          required
        />
        <br />

        <Form.Label htmlFor="message">Your Message:</Form.Label>
        <Form.Control
          as="textarea"
          rows="10"
          id="message"
          name="message"
          placeholder="Enter your message here"
          onChange={handleChange}
          value={inputs.message || ''}
          requried
        />

        <CallToAction
          onSubmit={handleFormSubmit}
          className="submit-button"
          type="submit"
          value="send"
          disabled={loading}
        >
          Send Message
        </CallToAction>
      </Form>
    </ContactFormStyles>
  );
}
